@import "core/styles/colors.scss";

.container-simplePage {
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 35px;
    ul {
        li {
            font-size: 14px;
        }
    }
}