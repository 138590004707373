@import "core/styles/colors.scss";

.container-contrat {
    .base_container {
        margin-bottom: 24px;
        .children_wrappe {
         
          span {
            font-size: 14px;
          }
        }
    }
    .gridJonction  {
      h3 {
        text-transform: none;
        letter-spacing:normal;
      }
    }
   
}

.gridJonction {
    @media only screen and (max-width: 900px) {
      padding-top: 0 !important;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .container-contrat {
      padding: 0;
      .base_container {
        padding: 16px;
      }
    }
  }
  