@import "core/styles/colors.scss";

.container-contrat {
    .offre {
        header {
            margin-bottom: 30px;
        }
        .all_infos {
            display: none;
            opacity: 0;
            flex-direction: column;
            transition: all 3s ease-in;
            &.isShow {
                animation: isShow .5s;
                display: flex;
		        opacity: 1;
            }
            &.isHide {
                animation: isHide .5s;
                display: none;
		        opacity: 0;
            }
           
        }
        .offre--description {
            padding: 0 48px;
            margin-bottom: 24px;
            
        }
        .offre-puissance {
            display: flex;
            flex-direction: column;
            align-items: center;
            .offre-title {
                margin-bottom: 8px;
            }
            .wrapper_info {
                h3 {
                    margin: 3px 0 6px;
                    text-transform: none;
                }
            }
        }
        .offre-tarif {
            display: flex;
            flex-direction: column;
            align-items: center;
            .offre-title {
                margin-bottom: 8px;
            }
            .wrapper_info {
                b {
                    font-size: 24px;
                    width: 100px;
                    word-break: break-all;
                    text-align: center;
                    line-height: 26px;
                    margin: 3px 0;
                }
            }
        }
        .wrapper_info {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 8px solid $red_light;
            border-radius: 50%;
            width: 150px;
            height: 150px;
            justify-content: center;
            b {
                color: $red_light;
                font-size: 35px;
            }
            h3 {
                font-size: 11px;
            }
            svg {
                width: 32px;
                height: 32px;
                path {
                    fill: $red_light;
                }
            }
        }
        .title-service {
            margin: 48px 0 24px 48px;
        }
        .zero-serviceActive {
            margin-left: 48px;
        }
        .wrapper_link {
            display: flex;
            align-items: center;
            justify-content:center;
            margin-top: 8px;
            margin-bottom: 8px;
        }
    }
}

@keyframes isShow {
	0% {
		display: none;
		opacity: 0;
		
	}
	100% {
		display: flex;
		opacity: 1;
	}
}


@keyframes isHide {
	0% {
        display: flex;
		opacity: 1;
		
	}
	100% {
		display: none;
		opacity: 0;
	}
}

// Resp
@media only screen and (max-width : 600px){
    .container-contrat {
        .offre {
            .title-service {
                margin-left: 0;
            }
            .offre-tarif {
                margin-top: 24px;
            }
            
        } 
    } 
    
  }
  

// Mobile
@media only screen and (max-width : 425px){
    .container-contrat {
        .offre {
            header { 
                word-break: break-all;
            }
            .offre--description {
                padding: 0;
            }
            .offre-tarif {
                margin-top: 24px;
            }
            .title-service {
                margin-left: 0;
            }
        } 
    } 
    
  }
  