@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.organize_modal {
  position: absolute;
  background: $white;
}

.modal_relative {
  position: relative;
}



.tableau_container thead tr .MuiTableCell-root.MuiTableCell-head{
  &:nth-of-type(2) {
    padding-right: 30px;
  }
}