@import "core/styles/colors.scss";

.actif_content {
  border: 2px solid $green_light;
  border-radius: 10px;
  display: flex;
  margin: 0 48px;
  .zone_picto {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $green_light;
    background-color: $green_light;
    padding: 24px;
    height: auto;
    width: 168px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }
  .zone_content {
    padding: 13px;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    .wrapper_statut {
      display: flex;
      align-items: center;
      margin: 17px 0;
      svg {
        width: 21px;
        height: 21px;
        margin-right: 6px;
      }
      p {
        margin: 0;
      }
    }
    .txt_Action {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      button {
        margin-left: 44px;
      }
    }
  }
}

// Resp
@media only screen and (max-width: 1440px) {
  .actif_content .zone_content .txt_Action {
    align-items: flex-end;
  }
}

// Resp
@media only screen and (max-width: 1300px) {
  .actif_content .zone_content .txt_Action {
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    button {
      margin-left: 0;
      margin-top: 12px;
    }
  }
}

// Resp
@media only screen and (max-width: 900px) {
  .actif_content .zone_content .txt_Action {
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    button {
      margin-top: 12px;
    }
  }
}

// Mobile
@media only screen and (max-width: 600px) {
  .actif_content {
    flex-direction: column;
    margin: 0;
    .zone_picto {
      height: 100%;

      width: 100%;
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
    }
    .zone_content {
      .txt_Action {
        align-items: stretch;
        flex-direction: column;
        button {
          margin-left: 0;
          margin-top: 24px;
          justify-content: center;
        }
      }
    }
  }
}
