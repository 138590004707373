@import "core/styles/colors.scss";

.informationUtile {
    header {
        h3 { 
           margin-bottom: 24px; 
        }
    }
    .hc_container, .depannage_container, .link_container {
        margin-top: 14px;
        .children_wrappe {
            margin-top: 14px;
            font-size: 14px;
            
        }
        .svg_wrappe {
            h3 {
                text-transform: none;
                letter-spacing: inherit;
              }
            width: auto !important;
            svg {
                width: 32px !important;
                height: 32px !important;
                background: $blue_accueil;
                border-radius: 50%;
                padding: 6px;
                margin-right: 8px !important;
                path {
                    fill: $white;
                }
              }
        }
            
        
        
    }
    .hc_container {
        margin-top: 20px;
        margin-bottom: 24px;

    }
    .depannage_container {
        margin-bottom: 24px;
        .children_wrappe {
            color: $gray_medium;
            font-size: 14px;
        }
        
    }
    .link_container {
        margin-top: 24px;
        .children_wrappe {
            display: inline-flex;
            flex-direction: column;
            .link {
                width:fit-content;
                svg {
                    height: 15px;
                    width: 15px;
                    margin-left: 9px;
                }
            }
        }
    }
    .panneText {
        display: inline-flex;
        flex-direction: column;
        align-items: baseline;
        .title {
            margin: 0;
            font-size: 14px;
        }
        svg {
            height: 15px;
            width: 15px;
            margin-left: 9px;
        }
    }

    .urgent {
        border: 3px solid $red_light;
        border-radius:10px;
        padding: 20px;
        margin-top: 15px;
        .title {
            svg {
                margin-right: 10px;
            }
            display: flex;
            align-items: center;
        }
        .urgent_content {
            display: flex;
            flex-direction: column;
            margin-top: 8px;
            margin-left: 30px;
            p {
                margin: 0;
                color: $gray_medium;
                font-size: 14px;
            }
        }
        .num_urgent {
            text-decoration: none;
        }
    }
}