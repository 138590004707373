@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";
.base_container {
  background: $white;
  border-radius: 10px;
  padding: 20px;
  width: auto;
  @include shadow;
}

.mb24 {
  margin-bottom: 24px;
}
