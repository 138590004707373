@import "core/styles/colors.scss";
.footer {
    background: $gray_strong;
    position: relative;
    z-index: 15;
    
   
    h1,h2,h3,h4, p, span, b {
        color: $white;
    }
    .footer--container {
        padding: 24px 0;
        h4 {
            margin-bottom: 4px;
        }
        .nav--footer ul{
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin-top: 8px;
                &:first-of-type {
                    margin-top: 0;
                }
                .link{
                    color: $white;
                   
                    &:before {
                        display: none;
                    }
                    &:hover {
                        color: $white;
                    }
                }
            }
        }
       
        .rs--container {
           
            .rs {
                a {
                    svg {
                        width: auto;
                        height: 16px;
                    }
                    &:nth-of-type(2) {
                        margin: 0 20px;
                    }
                    &:nth-of-type(3) {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
    .footer--end {
        background: $text;
        padding: 20px 30px;
        .image--footer {
            height: 40px;
        }
    }
}

// Mobile
@media only screen and (max-width : 600px){
    .footer .footer--container {
        .MuiGrid-item {
            margin-bottom: 30px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    
}

// Mobile
@media only screen and (max-width : 600px){
    .footer .footer--container {
        .MuiGrid-item {
            margin-bottom: 30px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    
}
