@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.contrat_itemList--wrapper {
  position: relative;
  background: $gray_light;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  border-radius: 10px;
  margin-bottom: 20px;
  transition: ease-out 300ms;
  header {
    padding: 14px 20px 0;
    .contrat_dateStart,
    .contrat_dateEnd {
      p,
      p b {
        color: $gray_medium;
      }
    }
  }
  .contrat_info--wrapper {
    padding: 12px 20px 14px;

    .contrat_address {
      text-transform: uppercase;
    }

    .contrat_pdl {
      padding: 8px 0;
    }
    .dateResiliation {
      padding-top: 8px;
      p {
        color: $red_alert;
      }
    }
  }
  &:hover {
    background: $white;
  }
}
