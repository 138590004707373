@import "core/styles/colors.scss";

.urgent {
  border: 3px solid $red_light;
  border-radius: 10px;
  padding: 20px;
  margin-top: 15px;
  .title {
    svg {
      margin-right: 10px;
    }
    display: flex;
    align-items: center;
  }
  .urgent_content {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-left: 30px;
    p {
      margin: 0;
      color: $gray_medium;
      font-size: 14px;
    }
  }
}

.link_container {
  margin-top: 24px;

  .link {
    width: fit-content;
    svg {
      height: 15px;
      width: 15px;
      margin-right: 9px;
    }
  }
}

.dash-bienvenue {
  padding-bottom: 12px;
}

@media only screen and (max-width: 600px) {
  .dash-bienvenue {
    padding: 12px 12px 0;
  }
}
