@import "core/styles/colors.scss";

.documentDL {
    header {
        margin-bottom: 24px;
    }
    .wrapper_doc {
        display: flex;
        flex-direction: column;
    
        .cta {
            margin-bottom: 24px;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            .picto {
                svg {
                    background: $blue;
                    width: 30px;
                    height: 30px;
                    padding: 5px;
                    border-radius: 5px;
                    margin-right: 8px;
                    path {
                        fill: $white    
                    }
                }
            }
           
           
            &:hover {
                .picto {
                    svg {
                        background: $blue_petrol_strong;
                    }
                }
                
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    .info {
        display: flex;
        margin-top: 24px;
        align-items: center;  
        .wrapper_picto {
            display: flex;
            svg {
                margin-right: 12px;
            }
        }
        .span_mesdoc {
            &:hover {
                color: $green_light;
            }
        }
    }
    
}

