.blocContentSmall {
    margin-bottom: 24px;
}

.blocContentSmall-title {
  font-weight: 700 !important;
  margin-bottom: 24px !important;
}

.orgBarreButton {
  margin: 0;

}
