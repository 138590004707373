@import "core/styles/colors.scss";

.svg-icon {
    &.primary path{
        fill: $green;
    }
    &.secondary path{
        fill: $blue_light_svg;
    }
    &.white path{
        fill: $white;
    }
    &.text path{
        fill: $text;
    }
    &.svgBlue path {
        fill: $blue_light_svg;
    }
    &.error path {
        fill: $red_alert;
    }

    &.bluePetrol path {
        fill: $blue_petrol_strong;
    }
}