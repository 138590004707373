@import "core/styles/mixins.scss";
@import "core/styles/colors.scss";

.toast {
  transition: right 0.4s ease-out;
  position: fixed;
  z-index: 11000;
  width: 353px;
  min-height: 209px;
  height: auto;
  top: 70px;
  right: 353px;
  background: url(../../../../core/assets/picto/logoBig.svg),
    radial-gradient(
      87.46% 87.46% at 100% 107.96%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 0.01%,
      rgba(255, 255, 255, 0) 100%
    );
  background-color: #2896af;
  background-blend-mode: saturation;
  background-position-x: right;
  background-position-y: center;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  padding: 40px 35px 30px;
  box-sizing: border-box;
  overflow: hidden;
  @include shadow;
  display: none;

  &.show {
    display: block;
    animation: enter-leave 6s ease-in-out;
   
  }
  .content {
    z-index: 1300;
  
    .MuiTypography-root {
      color: $white;
    }

  }

  .crossNotif {
    position: absolute;
    top: 13px;
    right: 20px;
    cursor: pointer;
    z-index: 1300;
  }
  .round-time-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .round-time-bar div {
    height: 5px;
    background: linear-gradient(to right, $blue_light, $blue_accueil);
    transform-origin: left center;
  }

  .round-time-bar[data-style="smooth"] div {
    animation: roundtime calc(var(--duration) * 1s) linear forwards;
  }

  @keyframes roundtime {
    to {
      /* More performant than animating `width` */
      transform: scaleX(0);
    }
  }

  @keyframes enter-leave {
    0% {
      right: -353px;
     
    }
    7.5% {
      right: 0;
    }
    92.5% {
      right: 0;
    }
    100% {
      right: -353px;
      
    }
  }



}


@media only screen and (max-width : 900px){

  .toast {
    min-height: auto;
    width: 290px;
    padding: 30px 24px;
    .content {
      h2 {
        font-size: 20px;
      }
    }
  }
}