@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.password_reset_container {
  min-height: calc(100vh - 65px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.password_reset_content {
  padding: 50px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 678px;
  position: relative;
  form {
    width: 100%;
    max-width: 300px;
  }
  .blue {
    color: $blue;
  }
  .container_btn {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    button {
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
  .password_checker {
    background: $white;
    @include shadow;
    border-radius: 8px;
    border: 1px solid $green;
    padding: 10px;
    width: 175px;
    position: absolute;
    top: 46%;
    left: 88%;
    transform: translate(-50%, -50%);
    z-index: 10;
    .checker {
      display: flex;
      align-items: center;
      p {
        margin: 6px;
        margin-left: 7px;
        text-align: left;
        padding: 0;
        font-size: 12px;
        &.isOk {
          text-decoration: line-through;
          margin-left: 12px;
        }
      }
    }
    .custom_arrow {
      position: absolute;
      top: 55%;
      left: -11px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .password_reset_content {
    .password_checker {
      position: inherit;
      left: auto;
      transform: none;
      width: 100%;
      margin: 20px 0;
      .custom_arrow {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .password_reset_content {
    width: 100%;
    padding: 16px 14px;
  }
}
