@import "core/styles/colors.scss";

.table_responsive {
    .MuiAccordion-root {
        background: $gray_light-2;
        border-radius:12px;
        box-shadow:none;
        margin-bottom: 20px;
        &:first-of-type {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
        &:before {
            display: none;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        .MuiAccordionSummary-root {
            align-items: flex-start;
            padding: 0 12px;
            .MuiAccordionSummary-content {
                flex-direction: column;
                margin: 14px 0;
                &.Mui-expanded {
                    
                }
                .MuiTypography-body {
                    margin-bottom: 22px;
                }
                .MuiTypography-subtitle {
                    display: flex;
                    flex-direction: row;
                    p {
                        margin: 0;
                        font-size: 14px;
                    }
                    .montant{
                        margin-right: 10px;
                        font-size: 14px;
                    }
                }
            }
            .MuiAccordionSummary-expandIconWrapper {
                margin-top: 18px;
                margin-right: 2px;
            }
        }
        .MuiAccordionDetails-root {
            padding: 10px 12px 14px;
            .accordion--content-main  {
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                   font-size: 14px;
                }
                .wrap_statut {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    margin-right: 25px;
                    .picto {
                        display: flex;
                        margin-right: 8px;
                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
            .accordion--content-cta {
                margin-top: 20px;
                .cta {
                    display: inline-flex;
                    align-items: center;
                    cursor: pointer;
                    svg {
                        background: $blue;
                        border-radius: 5px;
                        padding:5px;
                        height: 30px;
                        width: 30px;
                    }
                    p {
                        margin: 0 0 0 8px;
                        font-size: 14px;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
