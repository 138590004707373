.container-infosperso {
  .svg_wrappe {
    width: 32px !important;
    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
  .base_container {
    margin-bottom: 24px;
    .children_wrappe {
      span {
        font-size: 14px;
      }
    }
  }
  .paymentMeans {
    .children_wrappe {
      p {
        font-size: 14px;
      }
    }
  }
}

.fs16 {
  font-size: 16px;
  &.b {
    font-weight: 700;
  }
}

.fs14 {
  font-size: 14px !important;
}

.gridJonction {
  @media only screen and (max-width: 900px) {
    padding-top: 0 !important;
  }
}

@media only screen and (max-width: 600px) {
  .container-infosperso {
    padding: 4px 0 0;
  }
}

@media only screen and (max-width: 425px) {
  .container-infosperso {
    .paymentMeans {
      .MuiGrid-item {
        padding-left: 0;
      }
      .children_wrappe {
        overflow-wrap: anywhere !important;
      }
    }
  }
}
