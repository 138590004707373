@import "core/styles/colors.scss";

.link {
    position: relative;
    text-decoration: underline;
   
    svg {
      // transform: rotate(90deg);
      height: 10px;
      width: 8px;
      margin-left: 3px;
      path {
        fill: $green;
      }
    }
    &:hover {
      svg {
        path {
          fill: $green_light;
        }
      }
      
    }
    &.txt_open {
      svg {
        transform: rotate(-90deg);
      }
    }
  //   ** black
    &.black {
      color: $text;
      font-size: 14px;
      
      svg {
        path {
          fill: $text;
        }
      }
      &:hover {
          color: $green;
        svg {
          path {
            fill: $green;
          }
        }
        
      }
      &.txt_open {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }
  
