@import "core/styles/colors.scss";

.container_content {
    header {
        display: flex;
        align-items: center;
        svg {
            height: 22px;
            width: 22px;
        }
    }
}