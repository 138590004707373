// @import "../../../core/styles/colors.scss";
@import "core/styles/mixins.scss";
@import "core/styles/colors.scss";

.headerPage {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 30px;
    .wrapper {
        width: 100%;
        @include shadow;
        display: flex;
        height: 65px;
        position: relative;
        background: #ffffff;
        z-index: 2;
        border-radius: 0px 0px 10px 10px;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 30px;
            .left {
                .bloc_title {
                    display: flex;
                    align-items: center;
                    .logoPrimeo {
                        padding-left: 30px;
                        width: 98.51px;
                        height: 34px;  
                    }
                    .title {
                        margin: 0 0 0 40px;
                        font-size: 14px;
                    }
                }
                .menu_Resp_Burger {
                    height: 100%;
                    .burger{
                        height: 100%;
                        width: 60px;
                        padding: inherit;
                        border-radius: inherit;
                        max-height: inherit;
                        svg {
                            line {
                                stroke: $white;
                            } 
                        }
                        &.open {
                            background: $green_strong;
                        }
                    }
                }
                display: flex;
                align-items: center;
                height: 100%;
                
            }
            button {
                padding: 11px 12px 12px;
                &:hover {
                    background: $green_light;
                    border-color: $green_light;
                }
            }
        }
    }
    .container_bg {
        position: absolute;
        left: 0; 
        top:0;
        background: url('../../../core/assets/background_field.svg');
        background-repeat: no-repeat;
        background-size: cover;
        height: 148px;
        width: 100%;
        z-index: -1;
    }

}

// overwrite modal menu burger button
#menuBurger, #menuRight {
    .MuiPaper-elevation1.MuiPaper-root {
        // background: $green;
        color: $white;
        border-radius: 0;
        border-bottom-right-radius: 10px;
        left: 0 !important;
        bottom: 0;
        top: 65px !important;
        ul {
            padding:0;
            width: 300px;
        }
        li {
            text-transform: uppercase;
            height: 58px;
             background: $green;
            .icon {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    path {
                        fill: $white;
                    }
                }
            }
            p {
                color: $white;
                margin: 0 0 0 10px;
                padding-top: 2px;
                font-size: 14px;
            }
            &:hover {
                background: $green_light;
            }
            .MuiTouchRipple-root {
                display: none;
            }
        }
        .Mui-selected {
            background: $green_strong;
        }
        .TCO {
            transition: ease .2s .2s;
            background: $white;
            padding: 20px;
            text-align: center;
            h4 {
                margin: 0;
                font-size: 16px;
                line-height: 20px;
                text-transform: none;
                font-weight: 700;
            }
            p {
                font-size: 14px;
            }
            button {
                text-align: left;
                min-height: 60px;
                svg {
                  
                        width: 30px;
                        height: 19px;
                        margin-right: 10px;
                    
                }
                p {
                    color: $white;
                }
               
                &.btn_demenage {
                    margin-top: 12px;
                    padding: 0 12px;
                    min-height: 35px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    a {
                        display: flex;
                        text-decoration: none;
                        justify-content: center;
                        align-items: center;
                      }
                    p {
                      margin: 0;
                      color: $green;
                      transition:none;
                    }
                    svg {
                      path {
                        stroke: $green;
                      }
                    }
                    &:hover {
                      background: $green !important;
                      border-color: $green !important;
                      p {
                        color: $white !important;
                        transition:none;
                      }
                      svg {
                        path {
                          stroke: $white;
                        }
                      }
                    }
                  }
            }
        }
    }
}
#menuRight {
    .MuiPaper-elevation1.MuiPaper-root {
        right: 60px !important;
        left: initial !important;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        ul {
           width: 297px;
           li {
           
               
            
           }
        }
    }
}

// Tab
@media only screen and (max-width : 1024px){
    .headerPage {
        padding: 0;
        .wrapper {
            border-radius: 0;
        }
    }
  
    #menuRight .MuiPaper-elevation1.MuiPaper-root {
        right: 30px !important;
    }
}

// Mobile
@media only screen and (max-width : 425px){
    #menuBurger .MuiPaper-elevation1.MuiPaper-root,  #menuBurger .MuiPaper-elevation1.MuiPaper-root ul {
        width: 100%;
        max-width: 100%;
        bottom: 0;
    }
    .headerPage .wrapper .container {
        padding-right: 8px;
        .left .bloc_title {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 12px;
            .logoPrimeo {
                padding: 0;
            }
            .title {
                margin-left: 0;
            }
        } 
    } 
    #menuBurger .MuiPaper-elevation1.MuiPaper-root .TCO button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
        

    
}