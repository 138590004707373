@import "core/styles/colors.scss";

.checkboxButton {
  display: flex;
  padding: 14px 20px;
  width: auto;
  label {
    cursor: pointer;
    .MuiCheckbox-root {
      padding: 0 12px 2px 12px;
      &:hover {
        background: none;
      }
      .MuiSvgIcon-root {
        width: 17px;
        height: 17px;
        path {
          color: $text;
          fill:$text;
        }
      }
      &.Mui-checked {
        path {
          color: $blue;
          fill:$blue;
        }
      }
    }
    .MuiTouchRipple-root {
      display: none;
    }
  }
  &.gray {
    background: $gray_light;
    border-radius: 4px;
  }
}

.popin_checkbox {
  border-radius: 4px;
  border: none;
  background-color: $gray_light;
  padding: 10px;
  margin-bottom: 6px;
}
