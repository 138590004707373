@import "core/styles/colors.scss";

.anchor {
  display: inline-flex;
  margin: auto;
}

.spinner {
  color: $green;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    transform-origin: 40px 40px;
    animation: spinner-anim 1.5s linear infinite;
    .div-after {
      content: " ";
      display: block;
      position: absolute;
      top: 21px;
      left: 39px;
      width: 2px;
      height: 8px;
      border-radius: 20%;
      background: $green;
    }
  }
}

.addressLoader {
  width: 30px !important;
  height: 30px !important;
  top: -25px;
  right: 40px;
}

.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.5s;
}
.spinner div:nth-child(2) {
  transform: rotate(22.5deg);
  animation-delay: -1.4s;
}
.spinner div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -1.3s;
}
.spinner div:nth-child(4) {
  transform: rotate(67.5deg);
  animation-delay: -1.2s;
}
.spinner div:nth-child(5) {
  transform: rotate(90deg);
  animation-delay: -1.1s;
}
.spinner div:nth-child(6) {
  transform: rotate(112.5deg);
  animation-delay: -1s;
}
.spinner div:nth-child(7) {
  transform: rotate(135deg);
  animation-delay: -0.9s;
}
.spinner div:nth-child(8) {
  transform: rotate(157.5deg);
  animation-delay: -0.8s;
}
.spinner div:nth-child(9) {
  transform: rotate(180deg);
  animation-delay: -0.7s;
}
.spinner div:nth-child(10) {
  transform: rotate(202.5deg);
  animation-delay: -0.6s;
}
.spinner div:nth-child(11) {
  transform: rotate(225deg);
  animation-delay: -0.5s;
}
.spinner div:nth-child(12) {
  transform: rotate(247.5deg);
  animation-delay: -0.4s;
}
.spinner div:nth-child(13) {
  transform: rotate(270deg);
  animation-delay: -0.3s;
}
.spinner div:nth-child(14) {
  transform: rotate(292.5deg);
  animation-delay: -0.2s;
}
.spinner div:nth-child(15) {
  transform: rotate(315deg);
  animation-delay: -0.1s;
}
.spinner div:nth-child(16) {
  transform: rotate(337.5deg);
  animation-delay: 0s;
}
@keyframes spinner-anim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
