@import "../../../../core/styles/colors.scss";
@import "../../../../core/styles/mixins.scss";

.page_content_auth {
  // background: $blue_accueil;
  margin: 0 auto;
  text-align: center;
  position: relative;
  min-height: 100vh;
  height: 100%;

  .logoSmall-bg {
    position: absolute;
    bottom: -210px;
    right: -25px;
  }

  .greyline {
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    margin: 20px auto;
  }

  .sub_blue {
    color: $blue;
    font-size: 14px;
  }

  .authTitle {
    color: $white;
    margin-top: 120px;
  }

  .authContainerTitle {
    margin-bottom: 3px;
  }

  .container_white {
    @include shadow;
    margin: 50px 0 50px;
    border-radius: 8px;
    padding: 50px 120px 60px;
    z-index: 1;
    position: relative;

    h1 {
      text-align: center;
    }
    p {
      margin-top: 2px;
    }

    form {
      margin: 15px 0 10px;

      .form {
        display: flex;
        flex-direction: column;
        border: none;
      }

      .input-text {
        &:first-of-type {
          .MuiFormControl-root {
            margin-bottom: 25px;
          }
        }
      } 

      .error-message {
        opacity: 0;
        margin: 8px 0;
        color: $red_alert;
        text-align: center;
        &.isError {
          opacity: 1;
        }
      }

      .green {
        margin: 24px auto 10px;
        width: inherit;
      }

      .forgotPassword {
        text-decoration: underline;
        cursor: pointer;
      }

      .rememberMe {
        margin: 10px 0 10px -13px;
        padding: 0;
        .checkboxButton {
          padding: 0;
          span {
            padding-left: 0;
          }
        }
      }
    }

    .green {
      margin: 0 auto;
      margin-top: 10px;
    }
  }

  .logoBig-bg {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @media only screen and (max-width: 600px) {
    .authTitle {
      margin: 24px 0;
    }

    .container_white {
      margin: 0 0 50px;
      padding: 16px 14px;

      form {
        .green {
          width: 100%;
        }
      }
      .green {
        width: 100%;
      }
    }
  }
}
