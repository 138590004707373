// Base color

$white: #ffffff;
$text: #0e0e0e;

$gray_strong: #272727;
$gray_medium: #9d9d9d;
$gray_light: #f0f0f0;
$gray_light-2: #f8f8f8;

$green: #00644b;
$green_25: rgba(0, 100, 75, 0.25);
$green_light: #109f7b;
$green_strong: #104535;
$green_line: #e8f0ee;

$blue:#0ABBF0;
$blue_25:rgba(10, 187, 240, 0.25);
$blue_petrol_strong: #2896AF;
$blue_light: #E8F0EE;
$blue_accueil: #80DCF7;
$blue_light_svg: #57B9CF;

$red_alert_10: rgba(206, 37, 37, 0.1);
$red_alert: #CE2525;
$red_light: #D25F82;
