@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.container_cookies {
    background: $white;
    @include shadow;
    border-radius: 10px;
    padding: 34px 24px;
    max-width: 650px;
    width: 100%;

    position: fixed;
    z-index: 50;
    bottom: 10%;
    left: 30px;
    .wrapper_content {
        display: flex;
        margin-bottom: 24px;
        .picto {
            margin-left: 24px;
            svg {
                width: 65px;
                height: 65px;
            }
        }
    }
    .wrapper_options {
        .line_options {
            display: flex;
            margin-bottom: 20px;
            justify-content: space-between;
            
            
            .options_text {
                display: flex;
                flex-direction: column;
                .wrapper_title {
                    display: flex;
                    svg {
                        width: 24px ;
                        height: 24px ;
                        background: $blue_light_svg;
                        border-radius: 50%;
                        padding: 6px;
                       
                    }
                    .wrapper_text {
                        padding-left: 8px;
                        h3 {
                            padding-top: 3px;
                        }
                    }

                }
            }
        }
    }
    .wrapper_actions {
        display: flex;
        justify-content: space-between;
    
    }

}

// Resposnvie
@media only screen and (max-width : 710px){
    .container_cookies {
        left: 30px;
        right: 30px;
        max-width: -webkit-fill-available;
        .wrapper_options .line_options {
          flex-direction: column;
          .options_text {
              margin: 8px 0;
          }
          .orgBarreButton {
            margin-left: 32px;
          }
        }
        .wrapper_actions {
          flex-direction: column;
          button {
              margin-bottom: 4px;
          }
        }
  
    }
    
}

@media only screen and (max-width : 425px){
   .container_cookies {
      left: 8px;
      right: 8px;
      width: calc(100% - 8px);
  }
  .container_cookies .wrapper_content .picto svg {
    height: 40px;
    width: 40px;
  }
  .container_cookies .wrapper_content {
    h2 {
        font-size: 20px;
    }
  }

  
}