@import "core/styles/colors.scss";

.barreButton {
  margin-top: 20px;
  display: flex;
  input {
    display: none;
  }
  label {
    &:first-of-type {
      button {
        margin-right: 0px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:last-of-type {
      button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 0;
        border-left: none;
      }
    }
  }
  &.powerPDL {
    label {
      &:last-of-type {
        button {
          cursor: default;
          border-color: $gray_medium;
          color: $gray_medium;
        }
      }
    }
  }
}

.barreButton_error {
  color: $red_alert;
  font-family: "Circular-Std", Arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
