@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 19px 12px;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  max-height: 48px;
  line-height: 1;

  &.small {
    height: 30px;
    text-transform: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 7.5px 15px;
    margin: 0;
    p {
      margin: 0 10px;
      line-height: 0;
    }
  }
  &.medium {
    height: 35px;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 0;
    p {
      margin: 0 10px;
      line-height: 0;
    }
  }
  &.green {
    background: $green;
    color: $white;
    border: 1px solid $green;
    &.noTopRadius {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    &:active {
      background: $green_light;
      border-color: $green_light;
    }
    &.outline {
      border: 2px solid $green;
      color: $green;
      background: $white;
      border-color: $green;
      &.grey {
          color: rgb(163, 161, 161);
          border-color: rgb(163, 161, 161);
          cursor: progress;
        }
        &.checked {
          color: $white;
          background: $green;
          &.grey {
            background: rgb(163, 161, 161);
            border-color: rgb(163, 161, 161);
            cursor: progress;
        }
      }
      &:active {
        color: $green_light;
        border-color: $green_light;
      }
    }
    &.outlineSmall {
      max-height: 35px;
      border: 2px solid $green;
      color: $green;
      background: $white;
      border-color: $green;
    }
    &.reverse {
      color: $green;
      background-color: $white;
      &:disabled {
        color: $green;
        background-color: $white;
        border: 1px solid $green;
      }
    }
    &:disabled {
      background: $green_25;
      border: none;
      padding: 16px 20px 15px;
      cursor: default;
    }
  }
  &.blue {
    background: $blue;
    color: $white;
    border: 1px solid $blue;
    &:active {
      background: $blue_petrol_strong;
      border-color: $blue_petrol_strong;
    }

    &.outline {
      color: $blue;
      background: $white;
      border-color: $blue;
    }
    &.black {
      border-color: black;
    }
    &:active {
      border-color: $blue_petrol_strong;
    }

    &:disabled {
      background: $blue_25;
      border: none;
      padding: 16px 20px 15px;
      cursor: default;
    }
  }
  &.back {
    background: $white;
    border: 1px solid $blue;
    height: 48px;
    width: 48px;
    padding: 0;
    .icon {
      margin-left: 0;
      transform: rotate(180deg);
      path {
        fill: $blue;
      }
    }
    &:active {
      border-color: $blue_petrol_strong;
      .icon {
        path {
          fill: $blue_petrol_strong;
        }
      }
    }
  }
  &.green-v2 {
    background: $green;
    color: $white;
    border: 2px solid $green;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1;
    padding: 11px 15px 10px;
    font-weight: 500;
    &:active {
      background: $green_light;
      border-color: $green_light;
    }
    &.outline {
      color: $green;
      background: $white;
      border-color: $green;
      
      &.checked {
        background: $green;
        color: $white;
        
      }
      &:active {
        color: $green_light;
        border-color: $green_light;
      }
    }
    &.reverse {
      color: $green;
      background-color: $white;
      &:disabled {
        color: $green;
        background-color: $white;
        border: 2px solid $green;
      }
    }
    &:disabled {
      background: $green_25;
      border: none;
      // padding: 16px 20px 15px;
    }
  }
  &.square {
    padding: 14px 4px;
    &:disabled {
      background: $blue_25;
      padding: 14px 5px;
      cursor: default;
    }
  }
  .icon {
    margin-left: 12px;
    margin-bottom: 3px;
  }
  &.btn_cookies {
    position: fixed;
    bottom: 30px;
    left: 30px;
    z-index: 50;
    width: 44px;
    height: 44px;
    padding: 0;
    @include shadow;
  }
}
