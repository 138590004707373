@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.container-liste-contrats {
  background: url("../../core/assets/bigLogoBg.svg") no-repeat center top;
  background-color: $blue_accueil;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 30px 265px 30px;

  .liste {
    width: 100%;
    min-height: 166px;
  }

  .message {
    font-family: "Circular-Std", Arial, sans-serif;
    color: $white;
    font-weight: 700;
    font-size: 35px;
    line-height: 110%;
    margin-bottom: 20px;
  }
  .container-white {
    opacity: 1;
    background: $white;
    @include shadow;
    border-radius: 10px;
    display: flex;
    padding: 40px;
    width: 100%;
    max-width: 535px;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    position: relative;
    h1 {
      margin-bottom: 40px;
    }
    .bgLogo {
      position: absolute;
      bottom: -230px;
      right: -90px;
    }
  }
}

// Responsive
@media only screen and (max-width: 730px) {
  .container-liste-contrats .container-white .bgLogo {
    right: -8px;
  }
}

@media only screen and (max-width: 600px) {
  .container-liste-contrats {
    padding-top: 24px;
    .container-white {
      padding: 16px;
      h1 {
        text-align: center;
      }
      .bgLogo {
        display: none;
      }
    }
  }
}

// Mobile
@media only screen and (max-width: 425px) {
  .container-liste-contrats {
    padding: 24px 8px 60px 8px;
  }
}
