@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";


.container-contrat {
  .svg_wrappe {
    width: 28px !important;
    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
}

.content_with_tooltip {
    display: flex;
    align-items: center;
    
    svg {
        margin-left: 6px;
    }
    
    .wrapper_tooltip {
        position: relative;
        cursor: pointer;
        
        .wrapped_tooltip {
          position: absolute;
          top: 30px;
          left: -180px;
          float: right;
          padding: 10px 24px;
          margin: 0;
          min-width: 250px;
          border-radius: 10px;
          border: 2px solid $blue_petrol_strong;
          width: fit-content;
          background: $white;
          @include shadow;
          &::after {
            content: "";
            position: absolute;
            right: 42px;
            top: -10px;
            border-left:10px solid transparent;
            border-right:10px solid transparent;
            border-bottom: 10px solid $blue_petrol_strong;
          }
          p {
            margin: 0;
            overflow-wrap: break-word;
          }
        }
      }
}

@media only screen and (max-width: 600px) {
  .content_with_tooltip {
    .wrapper_tooltip {
      .wrapped_tooltip {
        left: inherit;
        right: 0;
        top: auto;
      }
    }
  }
}