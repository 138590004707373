@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.pagination_wrapper {
    display: flex;
    margin: 15px 0;
    padding: 0 25px ;
    .pagination_container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .pagination_nav {
            ul {
                display: flex;
                border-radius: 5px;
                padding: 0;
                li {
                    button {
                        background: $green;
                        border-radius:0;
                        color: $white;
                        align-items: baseline;
                        width: 36px;
                        height: 43px;
                        &:hover {
                            background: $green_light;
                        }
                        &.selected {
                            background: $green_strong;
                        }
                    }
                    .prevBtn {
                        padding: 16px 12px 12px;
                        width: 94px;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        svg {
                            margin-right: 5px;
                        }
                        span {
                            font-size: 14px;
                            color: $white;
                        }
                    }
                    .nextBtn {
                        padding: 16px 12px 12px;
                        width: 94px;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        svg {
                            margin-left: 5px;
                        }
                        span {
                            font-size: 14px;
                            color: $white;
                        }
                    }
                    .ellipsis {
                        width: 36px;
                        display: flex;
                        justify-content:center;
                        align-items:center;
                        height: 100%;
                        color: $white;
                        background: $green;
                        
                    }
                    
                }
            }
    
        }
        .pagination_row {
            width: 45%;
            display: flex;
            justify-content: flex-end;
            .MuiTablePagination-displayedRows, .MuiTablePagination-actions {
                display:none;
            }
            .MuiTablePagination-toolbar {
                border: 1px solid $text;
                border-radius: 63px;
                min-height: 45px;
                .MuiTablePagination-selectLabel {
                    margin-top: 11px;
                    margin-bottom: 9px;
                    font-family: "Circular-Std-book", Arial, sans-serif;
                    text-transform: uppercase;
                    line-height:1em;
                    cursor: default;
                    font-size: 11px;
                    font-weight: 500;
                }
                .MuiSelect-select {
                    font-family: "Circular-Std-book", Arial, sans-serif;
                    font-size: 11px;
                    padding-left: 5px;
                    padding-right: 22px;
                    text-align: left;
                    -webkit-text-align-last: left;
                    text-align-last: left;
                    width: auto;
                    z-index: 1;
                    font-weight: 500;
                    &:focus {
                        background: transparent;
                    }
                } 
                .MuiInputBase-root {
                    margin-right: 5px;
                    margin-top: 2px;
                    margin-left: 0;
                    svg {
                        width: 10px;
                        height: 0.7em;
                        position: absolute;
                        right: 7px;
                        path {
                            fill: $text;
                        }
                    }
                }
            }
        }
    }
}

// Responsive
// Tab
@media only screen and (max-width : 1024px){
    .pagination_wrapper {
        padding: 0;
        .pagination_container {
            flex-direction: column;
            align-items: flex-start;
            .pagination_row {
                margin-top: 20px;
                justify-content: left;
            }
        }
    } 
}

@media only screen and (max-width: 600px) {
    .pagination_wrapper .pagination_container .pagination_nav ul li .nextBtn, .pagination_wrapper .pagination_container .pagination_nav ul li .prevBtn {
        width: 43px;
    }
    .pagination_wrapper .pagination_container .pagination_nav ul li .prevBtn span, .pagination_wrapper .pagination_container .pagination_nav ul li .nextBtn span {
        display: none;
    }
    .pagination_wrapper .pagination_container .pagination_nav ul li .nextBtn svg {
        margin-left: 0;
    }
    .pagination_wrapper .pagination_container .pagination_nav ul li .prevBtn svg {
        margin-right: 0;
    }
    .pagination_wrapper .pagination_container .pagination_row {
        width: auto;
    }
}