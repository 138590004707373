@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.contrat_item--wrapper {
   
    position: relative;
    background: $gray_light;
    cursor: pointer;
    border-bottom: 1px solid rgba(0,0,0,0.1);
   
    header {
        padding: 14px 20px 0;
        
    }
    .contrat_info--wrapper {
        padding: 12px 20px 14px;

        .contrat_address {
            text-transform: uppercase;
        }
        
        .contrat_pdl {
            padding: 8px 0;
        }
        .dateResiliation, .contrat_status {
            padding-top: 8px;
           p {
            color: $red_alert;
           }
        }
    }
    &.selected {
        background: $white;
        border-bottom: 1px solid $white;
        &::before {
            content: "";
            width: 12px;
            height: 101%;
            background: $green_strong;
            position: absolute;           
        }
        .contrat_code {
            p b{
                color: $green;
            }
        }
        .contrat_dateStart, .contrat_dateEnd, .contrat_pdl {
            p, p b {
                color: $gray_medium;
               
            }
           
        }
    }
}