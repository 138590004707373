@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.layout_error {
    background: $text;
    overflow: hidden;
    .container404 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        height: calc(100vh - 148px);
        .wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            flex-direction: column;
            padding-top: 8px;
           
            
            h1, p {
                color: $white;
                z-index: 1;
            }
            h1 {
                font-size: 40px;
                margin-bottom: 9px;
            }
            p {
                font-size: 16px;
            }
            .relative {
                position: relative;
                display: flex;
                width: 100%;
                align-items: center;
                flex-direction: column;
                svg {
                    z-index: 5;
                    filter: drop-shadow(0px 30px 15px rgba(0, 0, 0, 0.7));
                }
                .ellipse-0, .ellipse-1, .ellipse-2, .ellipse-3, .ellipse-4, .ellipse-5 {
                    position: absolute;
                    border-radius: 50%;
                  
                   
                }
                .ellipse-0 {
                   
                    width: 76px;
                    height: 76px;
                    top: 160px;
                    background: $white;
                    mix-blend-mode: hard-light;
                    opacity: 0.85;
                    filter: blur(25px);
                    z-index: 5;
                    animation: lightShow0 5s ease-in-out;
                }
                .ellipse-1 {
                    background: $blue_light;
                    width: 104px;
                    height: 104px;
                    top: 158px;
                    mix-blend-mode: overlay;
                    filter: blur(40px);
                    z-index: 4;
                    animation: lightShow1 5s ease-in-out ;
                    
                }
                .ellipse-2 {
                    width: 208px;
                    height: 208px;
                    top: 102px;
                    background: $blue;
                    mix-blend-mode: overlay;
                    filter: blur(40px);
                    z-index: 3;
                    animation: lightShow2 5s ease-in-out  ;
                }
                .ellipse-3 {
                    width: 208px;
                    height: 208px;
                    top: 102px;
                    mix-blend-mode: overlay;
                    filter: blur(40px);
                    background: $blue;
                    z-index: 2;
                    animation: lightShow3 5s ease-in-out;
                }
                .ellipse-4 {
                    width: 514px;
                    height: 514px;
                    top: -51px;
                    background: $blue;
                    mix-blend-mode: hard-light;
                    opacity: 0.3;
                    filter: blur(40px);
                    animation: lightShow4 5s ease-in-out ;
                }
                .ellipse-5 {
                    width: 1120px;
                    height: 1120px;
                    top: -354px;
                    background: $blue;
                    mix-blend-mode: hard-light;
                    opacity: 0.3;
                    filter: blur(100px);
                    animation: lightShow5 5s ease-in-out  ;
                }

            }
            
        }
        .wrapper_btn {
            display: flex;
            margin-top: 33px;
            z-index: 1;
            button {
                padding: 11px 19px 8px;
                &:first-of-type {
                    margin-right: 56px;
                    background: $gray_light-2;
                    color: $green;
                    padding: 11px 56px 8px;
                }
               
            }
        }
        
    }
}

@keyframes lightShow0 {
   
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    31% {
        opacity: 0.85;
    }
    32% {
        opacity: 0.2;
    }
    33% {
        opacity: 0.85;
    }
    34% {
        opacity: 0.2;
    }
    36% {
        opacity: 0.85;
    }
    38% {
        opacity: 0.5;
    }
    40% {
        opacity: 0.85;
    }
    42% {
        opacity: 0.5;
    }
    45% {
        opacity: 0.85;
    }
    46% {
        opacity: 0.6;
    }
    48% {
        opacity: 0.85;
    }
    100% {
        opacity: 0.85;
    }
    
  }

@keyframes lightShow1 {
   
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    31% {
        opacity: 1;
    }
    32% {
        opacity: 0.5;
    }
    33% {
        opacity: 1;
    }
    34% {
        opacity: 0.5;
    }
    36% {
        opacity: 1;
    }
    38% {
        opacity: 0.8;
    }
    40% {
        opacity: 1;
    }
    42% {
        opacity: 0.8;
    }
    45% {
        opacity: 1;
    }
    46% {
        opacity: 0.8;
    }
    48% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
   
  }
  @keyframes lightShow2 {
   
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    31% {
        opacity: 1;
    }
    32% {
        opacity: 0.5;
    }
    33% {
        opacity: 1;
    }
    34% {
        opacity: 0.5;
    }
    36% {
        opacity: 1;
    }
    38% {
        opacity: 0.8;
    }
    40% {
        opacity: 1;
    }
    42% {
        opacity:0.8; 
    }
    45% {
        opacity: 1;
    }
    46% {
        opacity: 0.8;
    }
    48% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
    
  }
  @keyframes lightShow3 {
    
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    31% {
        opacity: 1;
    }
    32% {
        opacity: 0.5;
    }
    33% {
        opacity: 1;
    }
    34% {
        opacity: 0.5;
    }
    36% {
        opacity: 1;
    }
    38% {
        opacity: 0.8;
    }
    40% {
        opacity: 1;
    }
    42% {
        opacity: 0.8;
    }
    45% {
        opacity: 1;
    }
    46% {
        opacity: 0.8;
    }
    48% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
    
  }
  @keyframes lightShow4 {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    31% {
        opacity: 0.3;
    }
    32% {
        opacity: 0.1;
    }
    33% {
        opacity: 0.3;
    }
    34% {
        opacity: 0.1;
    }
    36% {
        opacity: 0.3;
    }
    38% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.3;
    }
    42% {
       opacity: 0.2; 
    }
    45% {
        opacity: 0.3;
    }
    46% {
        opacity: 0.25;
    }
    48% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.3;
    }
  }
  @keyframes lightShow5 {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    31% {
        opacity: 0.3;
    }
    32% {
        opacity: 0.1;
    }
    33% {
        opacity: 0.3;
    }
    34% {
        opacity: 0.1;
    }
    36% {
        opacity: 0.3;
    }
    38% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.3;
    }
    42% {
        opacity: 0.2;  
    }
    45% {
        opacity: 0.3;
    }
    46% {
        opacity: 0.25;
    }
    48% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.3;
    }
  }


  @media only screen and (max-width: 1120px) {
    .layout_error {
        .container404 {
            .wrapper {
               
                .ellipse-5{
                    // width: 100%;
                    // height: 100%;
                    // top: 0;
                }
            }
            
        }
    }
}

@media only screen and (max-width: 600px) {
    .layout_error {
        .container404 {
            .wrapper {
                padding: 0 8px;
                h1 { 
                    font-size: 30px;
                }
            }
            .wrapper_btn {
               flex-direction: column;
                justify-content:center;
                button {
                    &:first-of-type {
                        margin-bottom: 12px;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1120px) {
    .layout_error {
        .container404 {
            .wrapper {
                // .ellipse-4 {
                //     width: 257px;
                //     height: 257px;
                // }
                // .ellipse-5{
                //     width: 320px;
                //     height: 320px;
                //     top: 0;
                // }
            }
            
        }
    }
}



  
