// @import "../../../core/styles/colors.scss";
@import "core/styles/mixins.scss";
@import "core/styles/colors.scss";

.header {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0 30px;
  .wrapper {
    width: 100%;
    @include shadow;
    display: flex;
    height: 65px;
    position: relative;
    background: #ffffff;
    z-index: 2;
    border-radius: 0px 0px 10px 10px;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-right: 30px;
      .left {
        .bloc_title {
          display: flex;
          align-items: center;
          .logoPrimeo {
              padding-left: 30px;
              width: 98.51px;
              height: 34px;  
          }
          .title {
              margin: 0 0 0 40px;
              font-size: 14px;
          }
        
      }
    }
      button {
        padding: 11px 14px 11px;
       
        &:hover {
          background: $green_light;
          border-color: $green_light;
        }
      }
      .button-accueil {
        a {
          color: $green;
          text-decoration: none;
          font-size: 14px;
        }
        &:hover {
          background: $green;
          border-color: $green;
          a {
            color: $white;
          }
        }
      }
    }
  }
  .container_bg {
    position: absolute;
    left: 0;
    top: 0;
    background: url("../../../core/assets/background_field.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 148px;
    width: 100%;
    z-index: -1;
  }
}

// overwrite modal menu burger button
#menuRight {
  .MuiPaper-elevation1.MuiPaper-root {
    background: $green;
    color: $white;
    border-radius: 0;
    border-bottom-right-radius: 10px;
    left: 0 !important;
    ul {
      padding: 0;
      width: 235px;
    }
    li {
      text-transform: uppercase;
      height: 58px;
      .icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        color: $white;
        margin: 0 0 0 10px;
        padding-top: 2px;
        font-size: 12px;
      }
      &:hover {
        background: $green_light;
      }
      .MuiTouchRipple-root {
        display: none;
      }
    }
    .Mui-selected {
      background: $green_strong;
    }
    .TCO {
      transition: ease 0.2s 0.2s;
      background: $white;
      padding: 20px;
      text-align: center;
      h4 {
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        text-transform: none;
        font-weight: 700;
      }
      p {
        font-size: 14px;
      }
      button {
        text-align: left;
        min-height: 60px;
        svg {
          width: 30px;
          height: 19px;
          margin-right: 10px;
        }
        font-size: 15px;
      }
    }
  }
}

#menuRight {
  .MuiPaper-elevation1.MuiPaper-root {
    right: 60px !important;
    left: initial !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 58px;
    ul {
      width: 175px;
      
    }
  }
}

// Resp
@media only screen and (max-width: 1024px) {
  #menuRight .MuiPaper-elevation1.MuiPaper-root {
    right: 30px !important;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 0;
    .wrapper {
      border-radius: 0;
      .container {
        padding-right: 8px;
        .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .logoPrimeo {
            padding-left: 8px;
            width: 98.51px;
            height: 34px;
          }
          
        }
      }
    }
  }
}

// Mobile
@media only screen and (max-width : 425px){
 
  .header .wrapper .container {
      padding-right: 8px;
      .left .bloc_title {
          flex-direction: column;
          align-items: flex-start;
          margin-left: 12px;
          .logoPrimeo {
              padding: 0;
          }
          .title {
              margin-left: 0;
          }
      } 
  } 

  #menuRight .MuiPaper-elevation1.MuiPaper-root {
    right: 8px !important;
  }
 
      

  
}