@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.menuContrat_wrapper {
    margin-bottom: 16px;
    
    .menuContrat--header {
        display: flex;
        align-items: center;
        background: $green;
        height: auto;
        border-radius: 10px;
        padding: 10px 20px 15px;
        cursor: pointer;
        p {
            &:first-of-type {
                margin-bottom: 3px;
            }
            &.numberPDL {
                font-size: 12px;
            }
        }
        &.isOpen {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        .pdlSvg {
            width: 28px;
            height: 25px;
            margin-right: 12px;
        }
        .svgArrow {
            position: relative;
            top: -27px;
            right: 10px;
            transition: ease 0.2s 0.3s;
            opacity: 1;
        }
    
        p {
        color: $white;
        }
        .menuContrat--selected {
            width: 145px; 
            height: 68px;  
            transition: ease 0.2s 0.3s;
            opacity: 1;
            height: 100%;
           span,p, b {
            color: $white;
            line-height: 18px;
            display: flex;
            transition: ease 0.2s 0.3s;
            opacity: 1;
           }
           .p_address {
            text-transform: uppercase;
           }
        }
   }
   .menuContrat--content {
    
        @include shadow;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        opacity: 1;
        transition: ease 0.2s;
        .contrat_item--wrapper {
            &:last-of-type{
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            // &.selected {
            //     &:last-of-type::before {
            //         border-bottom-left-radius: 10px;
            //     }
            // }
        }
        &.close {
            display: none;
            width: 0;
            opacity: 0;
            transition: ease 0.2s;
        }
   }
}

.closed {
    .menuContrat_wrapper {
        .menuContrat--header {
            padding: 0 16px;
            height: 58px;
            .menuContrat--selected, .svgArrow {
                transition: ease 0.2s 0.1s;
                transition: height ease 0s 0.3s;
                width: 0;
                height: 0;
                opacity: 0;
                p {
                    display: none;
                }
            }
            .pdlSvg {
                margin-right: 0;
            }
        }
    }
}

// Responsive
@media only screen and (max-width : 1024px){
    .menuContrat_wrapper .menuContrat--header {
        border-radius: 0;
    }
    .menuContrat_wrapper {
        margin-bottom: 0;
    }
    .menuContrat_wrapper .menuContrat--content .contrat_item--wrapper:last-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .menuContrat_wrapper .menuContrat--content .contrat_item--wrapper.selected:last-of-type::before {
        border-bottom-left-radius: 0;
    }
    .menuContrat_wrapper .menuContrat--header .menuContrat--selected {
        width: 100%;
    }
}

// Responsive
@media only screen and (max-width : 425px){
    .menuContrat_wrapper .menuContrat--content .contrat_item--wrapper:last-of-type, .menuContrat_wrapper .menuContrat--content .contrat_item--wrapper.selected:last-of-type::before {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .menuContrat_wrapper .menuContrat--header .menuContrat--selected {
        width: 100%;
        height: auto;
    }
}