@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.blocConsoGraph {
  padding: 28px;
  .container-gray {
    background: $gray_light-2;
    border-radius: 5px;
    padding: 20px;
    position: relative;

    .header-container {
      // display: flex;
      // flex-wrap: nowrap;
      // justify-content: space-between;
      .grey {
        color: #9d9d9d;
      }
    }

    .chart-swap {
      height: 30px;
      width: 30px;
      background-color: $blue;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      top: 50%;
      position: absolute;
      z-index: 2;
      &.left {
        left: -5px;
      }
      &.right {
        right: -5px;
      }
    }

    .chart-container {
      margin: 30px 0 15px;
      background-color: $white;
    }

    .barreButton-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .barreButton {
      margin: 0 5px 5px;
      button.green.outline {
        width: 70px !important;
        height: 48px;
        padding: 10px 4px;
        text-transform: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .blocConsoGraph {
    padding: 8px;
    .container-gray {
      .chart-swap {
        top: 55%;
        height: 42px;
        width: 42px;
      }
    }
  }
}
