@import "core/styles/colors.scss";

.blocCodeParrain .gift {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    .picto {
        svg {
            background: $blue;
            width: 40px;
            height: 40px;
            padding: 1px;
            border-radius: 5px;
            margin-right: 8px;
            path {
                fill: $white    
            }
        }
    }
   
   
    &:hover {
        .picto {
            svg {
                background: $blue_petrol_strong;
            }
        }
        
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}

.blocCodeParrain .info {
        border: 3px solid $blue;
        border-radius: 10px;
        padding: 20px;
        margin-top: 15px;
        width:100%;
        .title{
            margin-bottom: 15px;
        }
}

.blocCodeParrain .cta {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    .picto {
        svg {
            background: $blue;
            width: 30px;
            height: 30px;
            padding: 5px;
            border-radius: 5px;
            margin-right: 8px;
            path {
                fill: $white    
            }
        }
    }
   
   
    &:hover {
        .picto {
            svg {
                background: $blue_petrol_strong;
            }
        }
        
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}

.blocCodeParrain .link {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    .picto {
        svg {
            width: 25px;
            height: 25px;
            padding: 5px;
            border-radius: 5px;
            margin-right: 8px;
        }
    }
   
    &:last-of-type {
        margin-bottom: 0;
    }
}
