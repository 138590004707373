@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.layout_error {
    background: $text;
    
    .container403 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 148px);
        .wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            flex-direction: column;
            h1, p {
                color: $white;
            }
            h1 {
                font-size: 40px;
                margin-bottom: 9px;
            }
            p {
                font-size: 16px;
            }
        }
        .wrapper_btn {
            display: flex;
            margin-top: 33px;
            button {
                padding: 11px 19px 8px;
                &:first-of-type {
                    margin-right: 56px;
                    background: $gray_light-2;
                    color: $green;
                    padding: 11px 56px 8px;
                }
               
            }
        }
        
    }
}


@media only screen and (max-width: 600px) {
    .layout_error {
        .container403 {
            .wrapper {
                padding: 0 8px;
                h1 { 
                    font-size: 30px;
                }
            }
            .wrapper_btn {
               flex-direction: column;
                justify-content:center;
                button {
                    &:first-of-type {
                        margin-bottom: 12px;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

