@import "core/styles/colors.scss";

.bloc_tableauFEP {
  .selectionTypes {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    .chose_tab {
      cursor: pointer;
      display: flex;
      justify-content: center;
      width: 50%;
      font-size: 16px;
      padding: 10px 0;
      background: $gray_light-2;
      &.selected {
        background: $white;
        border-bottom: 3px solid $blue;
      }

    }
  }
  .filter {
    background: $gray_light-2;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    flex-wrap: wrap;
    .container_combobox {
      margin-right: 12px;
      .MuiFormControl-root {
        min-width: 150px;
        //   margin: 15px 0 15px 0;
        .MuiInputLabel-root {
          top: 0;
          &.MuiFormLabel-filled,
          &.Mui-focused {
            top: -8px;
          }
        }
        .MuiOutlinedInput-root .MuiInputBase-input {
          padding: 5px 10px;
        }
      }
    }
    .inputText {
      margin-right: 12px;
      .MuiFormControl-root {
        // margin: 15px 0 15px 0;
        .MuiOutlinedInput-root .MuiInputBase-input {
          padding: 9px 0 9px 15px;
          width: 95px;
        }
        .MuiInputAdornment-root {
          margin-left: 0;
        }
        .MuiInputLabel-root {
          top: -7px;
          &.MuiFormLabel-filled,
          &.Mui-focused {
            top: -8px;
          }
        }
      }
    }
    button.blue {
      height: 30px;
      padding: 9px 19px 9px;
      margin: 15px 0;
    }
  }
  .wrapper_statut {
    display: flex;
    align-items: stretch;
    font-size: 14px;
    .picto {
      display: flex;
      margin-right: 5px;
      svg {
        width: 21px;
        height: 21px;
      }
    }
  }
  .btn_dl_table {
    &.cta {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      margin-top: 6px;
      margin-right: 10px;
      .picto {
        display: flex;
        svg {
          background: $blue;
          width: 30px;
          height: 30px;
          padding: 5px;
          border-radius: 5px;
          margin-right: 8px;
          path {
            fill: $white;
          }
        }
      }

      &:hover {
        .picto {
          svg {
            background: $blue_petrol_strong;
          }
        }
      }
    }
  }
  .table_responsive {
    margin-top: 20px;
  }
}
