@import "core/styles/colors.scss";
@import "core/styles/mixins.scss";

.container_btnBackTop { 
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 25;
    .btn_backTop {
        width: 44px;
        height: 44px;
        padding: 0;
        svg {
            transform: rotate(-90deg);
        }
    }
}

@media only screen and (max-width: 425px) {
    .container_btnBackTop {
        bottom: 8px;
        right: 8px;
    }
}

