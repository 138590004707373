.barrebutton-wrapper {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 899px) {
  .barrebutton-wrapper {
    flex-direction: row;
  }
}

@media only screen and (max-width: 600px) {
    .barrebutton-wrapper {
      flex-direction: column;
    }
  }
