@import "core/styles/colors.scss";

.main_container_layout {
  display: block;
 
}

// Mobile
@media only screen and (max-width : 425px){
  .main_container_layout {
    button.btn_cookies {
      left: 8px;
      bottom: 8px;
    }
  }
}





