@import "core/styles/colors.scss";

.main_container {
  display: flex;
  transition: ease 0.3s 0.1s;
}

.main_container--page {
  margin-left: 264px;
  padding: 0 0 24px 0;
  .main_container--wrapper {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding:60px 30px 0;
  }
}

// Mobile
@media only screen and (max-width : 600px){
  .main_container--page .main_container--wrapper {
    padding:24px 30px 0;
  }
  
}

// Mobile
@media only screen and (max-width : 425px){
  .main_container--page .main_container--wrapper {
    padding:24px 8px 0;
  }
  button.btn_cookies {
    left: 8px;
    bottom: 8px;
  }
  
}
