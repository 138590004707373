@import "core/styles/colors.scss";

.layoutAccueil {
  background-color: $blue_accueil;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .logoBig-bg {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

 
}

// Mobile
@media only screen and (max-width : 425px){
  .layoutAccueil {
    button.btn_cookies {
      left: 8px;
      bottom: 8px;
    }
  }
}
